<template>
  <div class="product-insights green-border-box">
    <div v-show="!loading">
      <h4 style="margin-left:15px;">Consumer Insights</h4><hr/>
      <div class="col-sm-6" >
        <h4 style="color:#782B55">{{ keys[0] }}</h4>
        <apexcharts  v-if="!loading"  :options="firstQuestionchartOptions" :series="firstQuestion"></apexcharts>
      </div>
      <div class="col-sm-6">
        <h4 style="color:#782B55">{{ keys[1] }}</h4>
        <apexcharts  v-if="!loading"  :options="secondQuestionchartOptions" :series="secondQuestion"></apexcharts>
      </div>
    </div>
    <h4 v-show="loading" style="text-align: center;">Loading Shopping Insights...</h4>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import { first } from '@amcharts/amcharts4/.internal/core/utils/Array'

export default {
  name: 'ProductInsights',
  components: {
    apexcharts: VueApexCharts
  },
  props: ['brandId'],
  data: function () {
    return {
      firstQuestion: [],
      secondQuestion: [],
      ShoppingInshgits: [],
      keys: [],
      loading: true,
      firstQuestionchartOptions: {
        chart: {
          title: {
            text: 'Products Insights'
          },
          type: 'pie'
        },
        labels: []

      },
      secondQuestionchartOptions: {
        chart: {
          title: {
            text: 'Products Insights'
          },
          type: 'pie'
        },
        labels: []

      },
      series: []
    }
  },
  methods: {
    getShoppingInsights () {
      this.$store.dispatch('getBrandShoppingInsights', this.brandId)
        .then(
          (response) => {
            this.keys = Object.keys(response.body)
            let firstQuestion = response.body[this.keys[0]]
            firstQuestion.forEach((q) => {
              this.firstQuestionchartOptions.labels.push(q.x)
              this.firstQuestion.push(q.y)
            })
            let secondQuestion = response.body[this.keys[1]]
            secondQuestion.forEach((q) => {
              this.secondQuestionchartOptions.labels.push(q.x)
              this.secondQuestion.push(q.y)
            })
            this.loading = false
            console.log(this.firstQuestion)
          }
        )
    }
  },

  mounted () {
    this.getShoppingInsights()
  },
  watch: {

  }
}
</script>
