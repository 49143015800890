<template>
    <div class="product-insights green-border-box" style="margin-bottom: 20px;;">
      <div v-if="!loading" class="row">
        <div class="row" style="margin-left:1%;">
        <div class="col-sm-9">
          <h4 style="margin-left:5px;">PICKL Activities</h4>
        </div>
        <div class="col-sm-3">
          <div class=" nopadd text-center sort-control">
            View By last
            <select v-model="viewby">
            <option selected value="1y">12 Months</option>
            <option selected value="6m">6 Months</option>
            <option selected value="3m">3 Months</option>
            <option selected value="1m">1 Month</option>
            </select>
          </div>
        </div>
        </div>
        <div class="row" id="chart">
          <apexcharts height="300" type="area" :options="chartOptions" :series="chartData"/>

        </div>
      </div>
      <h4 v-else style="text-align: center;">Loading Pickl Insights...</h4>
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'ProductInsights',
  components: {
    apexcharts: VueApexCharts
  },
  props: ['brandId'],
  data: function () {
    return {
      viewby: '1y',
      loading: true,
      chartOptions: {
        chart: {
          id: 'pickl-graph',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        xaxis: {
          labels: {
            rotate: 0
          }
        },
        markers: {
          size: 0,
          style: 'hollow',
          strokeColor: '#782b54',
          colors: '#ffffff'
        },
        colors: ['#00E05E', '#EBFCF2'],
        grid: {
          borderColor: '#F2F2F2',
          clipMarkers: false
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'solid',
          colors: ['#00e05e'],
          opacity: 0.08,
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 1,
            opacityTo: 1
          }
        },
        stroke: {
          width: 2,
          colors: ['#00E05E']
        },
        tooltip: {
          x: {
            show: false
          },
          custom: function ({ series, seriesIndex, dataPointIndex }) {
            return '<div class="graph-tooltip">' + series[seriesIndex][dataPointIndex] + '</div>'
          },
          marker: {
            show: true
          }
        },
        yaxis: {
          min: 0,
          tickAmount: 4,
          labels: {
            style: {
              cssClass: 'graph-label'
            }
          }
        }
      },
      chartData: []
    }
  },
  methods: {

    getPicklsInsights () {
      this.$store.dispatch('getBrandPicklInsights', { brandId: this.brandId, viewby: this.viewby })
        .then(
          (response) => {
            this.allChartData = response.body
            this.showChartData()
          }
        )
    },
    showChartData () {
      this.chartData = [{
        name: 'PICKL',
        data: this.allChartData.pickl_data
      }]
      this.loading = false
    }
  },

  mounted () {
    this.getPicklsInsights()
  },
  watch: {
    viewby () {
      this.getPicklsInsights()
    }
  }
}
</script>
