var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-insights green-border-box" }, [
    !_vm.loading
      ? _c("div", [
          _c("div", { staticClass: "col-sm-6" }, [
            _c("h3", { staticStyle: { "text-align": "center" } }, [
              _vm._v("Geo-Located Completion Time")
            ]),
            _c("table", { staticClass: "table table-striped" }, [
              _vm._m(0),
              _vm.geoData
                ? _c(
                    "tbody",
                    _vm._l(_vm.geoData, function(value, key) {
                      return _c("tr", { key: key }, [
                        _c("td", [_vm._v(_vm._s(key))]),
                        _c("td", [_vm._v(_vm._s(value))])
                      ])
                    }),
                    0
                  )
                : _c("tbody", [
                    _c("tr", { staticStyle: { "text-align": "center" } }, [
                      _vm._v("Loading Data...")
                    ])
                  ])
            ])
          ]),
          _c("div", { staticClass: "col-sm-6" }, [
            _c("h3", { staticStyle: { "text-align": "center" } }, [
              _vm._v("Go-Anywhere Completion Time")
            ]),
            _c("table", { staticClass: "table table-striped" }, [
              _vm._m(1),
              _vm.nonGeoData
                ? _c(
                    "tbody",
                    _vm._l(_vm.nonGeoData, function(value, key) {
                      return _c("tr", { key: key }, [
                        _c("td", [_vm._v(_vm._s(key))]),
                        _c("td", [_vm._v(_vm._s(value))])
                      ])
                    }),
                    0
                  )
                : _c("tbody", [
                    _c("tr", { staticStyle: { "text-align": "center" } }, [
                      _vm._v("Loading Data...")
                    ])
                  ])
            ])
          ])
        ])
      : _c("h4", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Loading Average  Time Completion of tasks...")
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("\n              Journey\n            ")]),
        _c("th", [_vm._v("\n              Average Time\n            ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("\n              Journey\n            ")]),
        _c("th", [_vm._v("\n              Average Time\n            ")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }