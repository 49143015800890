var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-insights green-border-box" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loading,
            expression: "!loading"
          }
        ]
      },
      [
        _c("h4", { staticStyle: { "margin-left": "15px" } }, [
          _vm._v("Consumer Insights")
        ]),
        _c("hr"),
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c("h4", { staticStyle: { color: "#782B55" } }, [
              _vm._v(_vm._s(_vm.keys[0]))
            ]),
            !_vm.loading
              ? _c("apexcharts", {
                  attrs: {
                    options: _vm.firstQuestionchartOptions,
                    series: _vm.firstQuestion
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c("h4", { staticStyle: { color: "#782B55" } }, [
              _vm._v(_vm._s(_vm.keys[1]))
            ]),
            !_vm.loading
              ? _c("apexcharts", {
                  attrs: {
                    options: _vm.secondQuestionchartOptions,
                    series: _vm.secondQuestion
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]
    ),
    _c(
      "h4",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticStyle: { "text-align": "center" }
      },
      [_vm._v("Loading Shopping Insights...")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }