<template>
    <div class="product-insights green-border-box">
      <div v-if="!loading">
        <marquee :v-html="issuesText" style="font-size: 35px;" scrollamount="20"></marquee>
      </div>
      <h4 v-else style="text-align: center;">Loading Issues....</h4>
    </div>
</template>
<script>

export default {
  name: 'ProductInsights',
  props: ['brandId'],
  data: function () {
    return {
      issuesInsight: [],
      issuesText: '',
      loading: true,
      geoData: [],
      nonGeoData: []
    }
  },
  methods: {
    getIssuesIngithts () {
      this.$store.dispatch('getBrandIssuesInsights', this.brandId)
        .then(
          (response) => {
            this.issuesInsight = response.body
            response.body.forEach((issue) => {
              this.issuesText = this.issuesText + ' ' + issue.issues_count + ' users said: <span style="color:red;margin-left:10px;">' + issue.issue + '</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
            })
            this.loading = false
          }
        )
    }
  },

  mounted () {
    this.getIssuesIngithts()
  },
  watch: {

  }
}
</script>
