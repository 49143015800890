<template>
    <div class="product-insights">
    <div>
            <h2>{{ brandId }}</h2>
    </div>
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'ProductInsights',
  components: {
    apexcharts: VueApexCharts
  },
  props: ['brandId'],
  data: function () {
    return {

    }
  },
  methods: {

  },
  created () {
    this.brandId = this.$store.state.userData.brandId
    this.onBoarded = this.$store.state.userData.onBoarded
    console.log(this.$store.state.userData)
  },

  mounted () {
    let userData = this.$store.state.userData
    this.userRole = userData.role
    if (this.userRole === 'super-admin') {
      this.userUrl = 'sadmin'
    } else {
      this.userUrl = 'staff'
    }
  },
  watch: {

  }
}
</script>
