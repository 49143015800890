<template>
    <div class="product-insights green-border-box">
      <div v-show="!loading">
        <h4 style="margin-left:15px;">Campaign Comparision</h4>
          <apexcharts  v-if="!loading"  :options="chartOptions" :series="series"></apexcharts>
      </div>
      <h4 v-show="loading" style="text-align: center;">Loading Products Insights...</h4>
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'ProductInsights',
  components: {
    apexcharts: VueApexCharts
  },
  props: ['brandId'],
  data: function () {
    return {
      loading: true,
      chartOptions: {
        chart: {
          title: {
            text: 'Pickl type Insights'
          },
          type: 'pie'
        },
        labels: []

      },
      series: []
    }
  },
  methods: {
    getPicklTypeInsights () {
      this.$store.dispatch('getBrandPicklTypeInsights', this.brandId)
        .then(
          (response) => {
            console.log(response.body)
            this.series = []
            this.chartOptions.labels = []
            response.body.forEach((product) => {
              this.chartOptions.labels.push(product.x)
              this.series.push(product.y)
            })
            this.loading = false
          }
        )
    }
  },

  mounted () {
    this.getPicklTypeInsights()
  },
  watch: {

  }
}
</script>
