<template>
    <div class="product-insights green-border-box">
      <div v-if="!loading">
        <div class="col-sm-6">
            <h3 style="text-align:center;">Geo-Located Completion Time</h3>
            <table class="table table-striped">
            <thead>
              <tr>
                <th>
                  Journey
                </th>
                <th>
                  Average Time
                </th>
              </tr>
            </thead>
            <tbody v-if="geoData">
              <tr v-for="(value, key) in geoData" :key="key">
                <td>{{key}}</td>
                <td>{{value}}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr style="text-align:center;">Loading Data...</tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm-6">
            <h3 style="text-align:center;">Go-Anywhere Completion Time</h3>
            <table class="table table-striped">
            <thead>
              <tr>
                <th>
                  Journey
                </th>
                <th>
                  Average Time
                </th>
              </tr>
            </thead>
            <tbody v-if="nonGeoData">
              <tr v-for="(value, key) in nonGeoData" :key="key">
                <td>{{key}}</td>
                <td>{{value}}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr style="text-align:center;">Loading Data...</tr>
            </tbody>
          </table>
        </div>
      </div>
      <h4 v-else style="text-align: center;">Loading Average  Time Completion of tasks...</h4>
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'ProductInsights',
  components: {
    apexcharts: VueApexCharts
  },
  props: ['brandId'],
  data: function () {
    return {
      loading: true,
      geoData: [],
      nonGeoData: []
    }
  },
  methods: {
    getAverageCompletionTime (type) {
      this.$store.dispatch('getBrandAverageCompletionTimes', { brandId: this.brandId, type: type })
        .then(
          (response) => {
            if (type === 'GEO') {
              this.geoData = response.body.pickl_data
            } else {
              this.nonGeoData = response.body.pickl_data
            }
            this.loading = false
          }
        )
    }
  },

  mounted () {
    this.getAverageCompletionTime('GEO')
    this.getAverageCompletionTime('NON_GEO')
  },
  watch: {

  }
}
</script>
