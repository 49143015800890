var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-insights green-border-box" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loading,
            expression: "!loading"
          }
        ]
      },
      [
        _c("h4", { staticStyle: { "margin-left": "15px" } }, [
          _vm._v("Campaigns")
        ]),
        !_vm.loading
          ? _c("apexcharts", {
              attrs: { options: _vm.chartOptions, series: _vm.series }
            })
          : _vm._e()
      ],
      1
    ),
    _c(
      "h4",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticStyle: { "text-align": "center" }
      },
      [_vm._v("Loading Products Insights...")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }