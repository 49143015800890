var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-insights green-border-box" }, [
    !_vm.loading
      ? _c(
          "div",
          [
            _c("h4", { staticStyle: { "margin-left": "15px" } }, [
              _vm._v("Merchant Activites")
            ]),
            _c("apexcharts", {
              attrs: { options: _vm.chartOptions, series: _vm.series }
            })
          ],
          1
        )
      : _c("h4", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Loading Retailers Insights...")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }