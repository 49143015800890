var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-insights green-border-box" }, [
    !_vm.loading
      ? _c(
          "div",
          [
            _c("marquee", {
              staticStyle: { "font-size": "35px" },
              attrs: { "v-html": _vm.issuesText, scrollamount: "20" }
            })
          ],
          1
        )
      : _c("h4", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Loading Issues....")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }