<template>
    <div class="product-insights green-border-box">
      <div v-show="!loading">
        <h4 style="text-align: center;">User Selfies</h4>
        <img :src="image" class="img-responsive" style="width: 100%;"/>
        <span style="font-size: 13px;font-weight: bold;text-align: center;display: block;">{{ storeAddress }}</span>
      </div>
      <h4 v-show="loading" style="text-align: center;">Loading Products Insights...</h4>
    </div>
</template>
<script>
import { time } from '@amcharts/amcharts4/core'

export default {
  name: 'ProductInsights',
  components: {
  },
  props: ['brandId'],
  data: function () {
    return {
      loading: true,
      localTime: '',
      selfies: [],
      image: '',
      userName: '',
      storeName: '',
      storeAddress: ''
    }
  },
  methods: {
    getBrandSocialSelfies () {
      this.$store.dispatch('getBrandSocialSelfies', this.brandId)
        .then(
          (response) => {
            console.log(response.body)
            this.selfies = response.body
            this.loading = false
            this.showLocaleTime()
            this.image = this.selfies[0]['image'] + '?width=300'
            this.storeAddress = this.selfies[0]['address']
            this.userName = this.selfies[0]['name']
            this.storeName = this.selfies[0]['store']
          }
        )
    },
    showLocaleTime: function () {
      var here = this
      let counter = 1
      setInterval(function () {
        here.image = here.selfies[counter]['image'] + '?width=300'
        here.storeAddress = here.selfies[counter]['address']
        here.userName = here.selfies[counter]['name']
        here.storeName = here.selfies[counter]['store']
        counter++
        if (counter === (here.selfies.length - 1)) {
          counter = 0
        }
      }, 5000)
    }
  },

  mounted () {
    this.getBrandSocialSelfies()
  },
  watch: {

  }
}
</script>
