var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-insights green-border-box" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loading,
            expression: "!loading"
          }
        ]
      },
      [
        _c("h4", { staticStyle: { "text-align": "center" } }, [
          _vm._v("User Selfies")
        ]),
        _c("img", {
          staticClass: "img-responsive",
          staticStyle: { width: "100%" },
          attrs: { src: _vm.image }
        }),
        _c(
          "span",
          {
            staticStyle: {
              "font-size": "13px",
              "font-weight": "bold",
              "text-align": "center",
              display: "block"
            }
          },
          [_vm._v(_vm._s(_vm.storeAddress))]
        )
      ]
    ),
    _c(
      "h4",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticStyle: { "text-align": "center" }
      },
      [_vm._v("Loading Products Insights...")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }