<template>
    <div class="product-insights green-border-box">
      <div v-show="!loading">
        <h4 style="margin-left:15px;">Campaigns</h4>
          <apexcharts  v-if="!loading"  :options="chartOptions" :series="series"></apexcharts>
      </div>
      <h4 v-show="loading" style="text-align: center;">Loading Products Insights...</h4>
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'ProductInsights',
  components: {
    apexcharts: VueApexCharts
  },
  props: ['brandId'],
  data: function () {
    return {
      loading: true,
      chartOptions: {
        chart: {
          height: 200,
          title: {
            text: 'Products Insights'
          },
          type: 'pie'
        },
        labels: []

      },
      series: []
    }
  },
  methods: {
    getProductInsights () {
      this.$store.dispatch('getBrandProductInsights', this.brandId)
        .then(
          (response) => {
            this.series = []
            this.chartOptions.labels = []
            let productInsights = response.body
            response.body.forEach((product) => {
              this.chartOptions.labels.push(product.name)
              this.series.push(product.product_count)
            })
            console.log(this.series)
            this.loading = false
          }
        )
    }
  },

  mounted () {
    this.getProductInsights()
  },
  watch: {

  }
}
</script>
<style scoped>
  .apexcharts-canvas {
    max-height: 300px !important;
  }
</style>
