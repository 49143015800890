<template>
  <div class="product-insights green-border-box">
    <div v-if="!loading">
        <h4 style="margin-left:15px;">Merchant Activites</h4>
        <apexcharts :options="chartOptions" :series="series"></apexcharts>
    </div>
    <h4 v-else style="text-align: center;">Loading Retailers Insights...</h4>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'ProductInsights',
  components: {
    apexcharts: VueApexCharts
  },
  props: ['brandId'],
  data: function () {
    return {
      loading: true,
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            borderRadius: 1,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: []
        }
      },
      series: [ {
        data: []
      }
      ]
    }
  },
  methods: {
    getRetailerInsights () {
      this.$store.dispatch('getBrandRetailerInsights', this.brandId)
        .then(
          (response) => {
            this.series[0].data = response.body
            this.loading = false
          }
        )
    }
  },

  mounted () {
    this.getRetailerInsights()
  },
  watch: {

  }
}
</script>
